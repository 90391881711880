const DEFAULT_BIG_COLUMN_SIZE = 500
const MIN_COLUMN_SIZE = 18
const MAX_COLUMN_SIZE_MARGIN = 50
const MAGIC_SPACING = 12

export const useTableColumnSize = () => {
  // TODO: Default size of known column should be returned from the API
  const columnSizes: Record<string, number> = {
    lease__Name: 350,
    propertyName: 200,
    propertyAddress: 200,
    commencementDate: 140,
    shopNumber: 65,
    expiryDate: 90,
    Remaining: 140,
    calc__AreaInSqm: 80,
    calc__PercentageOfArea: 80,
    calc__InitialRent: 115,
    calc__RemainingYears: 110,
    calc__InitialRentPerSqm: 140,
    calc__NextReviewDate: 120,
    calc__NextReviewType: 130,
    optionTerm: 120,
    outgoingsAgreedPercent: 170,
    calc__OutgoingsPerSqm: 130,
    calc__OutgoingsPerAnnum: 95,
    calc__MarketingLevyPerAnnum: 130,
    turnoverPercentageRent: 130,
    permittedUse: 250,
    tradingHours: 200,
    lessorName: 200,
    lesseeName: 200,
    lesseeTradingName: 200,
    propertyLease__Sector: 60,
    lease__Status: 100,
    guarantor: 200,
  }
  const { width } = useWindowSize()

  const getColumnSize = (
    accessor: string,
    headerText: string,
    size?: number,
  ) => {
    if (size) return size

    if (
      accessor.endsWith('Clause') ||
      accessor.endsWith('Definition') ||
      accessor.endsWith('Notes') ||
      accessor.endsWith('IncentiveDetails') ||
      accessor.endsWith('Table')
    ) {
      return DEFAULT_BIG_COLUMN_SIZE
    }

    const headerSize = headerText.length * MAGIC_SPACING
    return columnSizes[accessor] ?? headerSize
  }

  return {
    getColumnSize,
    minSize: computed(() => MIN_COLUMN_SIZE),
    maxSize: computed(() => width.value - MAX_COLUMN_SIZE_MARGIN),
  }
}
