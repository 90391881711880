<script setup lang="ts">
import type { SortingState } from '@tanstack/vue-table'
import type { ReportTotals, ReportColumnInfo, ReportRow } from '@reports'
import { useProvideDynamicCell, useTableColumns } from './composables'
import type { ReportTableColumn, ReportTableFooter } from './types'
import type { FetchError } from 'ofetch'

interface Props {
  divisionId: string
  columns: ReportColumnInfo[]
  data: Record<string, any>[] | undefined
  totalRecords: number
  tableFooter?: ReportTotals | null
  loading?: boolean
  fetching?: boolean
  filterCount?: number
  height?: string
  clickable?: boolean
  error: FetchError | null
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  fetching: false,
  tableFooter: undefined,
  filterCount: undefined,
  totalRecords: 0,
  height: undefined,
  clickable: false,
  error: null,
})

const sort = defineModel<SortingState>('sort')
const selection = defineModel<Record<string, any>[]>('selection')

const emit = defineEmits<{
  retry: []
  fetchNextPage: []
  'click:row': [item: ReportRow]
}>()

// AUTH
const { data: user } = useAuth()

// TABLE
const { columns, hasFooter } = useTableColumns(props)
const { getCellComponent } = useProvideDynamicCell()
const onFetch = () => {
  emit('fetchNextPage')
}
const onClickRow = (item: ReportRow) => {
  emit('click:row', item)
}

const retryCount = ref(0)

const retry = () => {
  retryCount.value++
  emit('retry')
}

watch(
  () => props.data,
  () => {
    retryCount.value = 0
  },
)
</script>

<template>
  <DataTable
    v-model:sort="sort"
    v-model:selection="selection"
    :columns="columns"
    :data="data"
    :loading="loading"
    :fetching="fetching"
    :footer="hasFooter"
    :persist="
      user ? { key: `${user.id}:reports`, columnSizing: true } : undefined
    "
    striped-rows
    show-gridlines
    resizable
    sortable="manual"
    :virtual-scroller="{
      estimateSize: () => 40,
      rowsOverscan: 20,
      columnsOverscan: 10,
    }"
    :height="height"
    :total-records="totalRecords"
    infinite-scroll
    :sticky="height ? undefined : { offsetHeader: 60 }"
    :clickable="clickable"
    @fetch-next-page="onFetch"
    @click:row="onClickRow"
  >
    <!-- EMPTY STATE -->
    <template #empty>
      <div class="mt-8 flex flex-col items-center pb-12 pt-8">
        <div v-if="error" class="flex max-w-lg flex-col gap-6">
          <!-- 404 not found -->
          <h1 v-if="error.status === 404" class="font-bold">
            Report not found
          </h1>

          <!-- Error 500 -->
          <template v-else-if="error.status && error.status >= 500">
            <h1 class="block font-bold">
              Oops! Something went wrong on our end.
            </h1>

            <Icon
              name="crash"
              class="flex self-center text-8xl text-gray-500"
            />

            Our team has been notified, and we're working hard to fix it. Please
            try again later. We apologize for any inconvenience this may have
            caused.

            <div v-if="retryCount <= 3" class="inline-flex self-center">
              <Button color="secondary" @click="retry">Try again</Button>
            </div>
          </template>

          <!-- Generic error -->
          <span v-else class="break-words">
            An error occurred:
            {{ error?.message ?? error }}

            <Button v-if="retryCount <= 3" color="secondary" @click="retry">
              Try again
            </Button>
          </span>
        </div>

        <!-- Empty state -->
        <template v-else>
          <h1>No leases found</h1>

          <p v-if="filterCount">
            {{ filterCount }}
            {{ filterCount === 1 ? 'filter' : 'filters' }} are applied, try to
            change the filters and check again
          </p>

          <Icon name="no-lease" class="my-8 text-8xl text-gray-200" />

          <p>
            <Button color="primary" :to="`/division/${divisionId}/workflow`">
              Review and add a lease to portfolio
            </Button>
          </p>
        </template>
      </div>
    </template>

    <!-- TODO: Status component should be based on `uiConfig.component` -->
    <!-- Lease Status -->
    <template
      #item-lease__Status="slotProps: ReportTableColumn<'lease__Status'>"
    >
      <ReportsTableCellStatus v-bind="slotProps" />
    </template>

    <!-- ALL OTHER ITEMS -->
    <template #item="slotProps: ReportTableColumn">
      <component
        :is="getCellComponent(slotProps.uiConfig?.component)"
        v-bind="slotProps"
      />
    </template>

    <!-- FOOTER -->
    <template #footer="{ value, index }: ReportTableFooter">
      <template v-if="index === 0">
        <span>
          {{ value ? `TOTAL: ${value.value}` : 'TOTAL' }}
        </span>
      </template>
      <span v-else>{{ value?.value ?? '-' }}</span>
    </template>
  </DataTable>
</template>
