import { Sector } from '~/data-services/gen'
import {
  createColumnHelper,
  type CellContext,
  type ColumnDef,
  type ColumnDefTemplate,
} from '@tanstack/vue-table'
import type {
  ReportRowOriginal,
  ReportTotals,
  ReportColumnInfo,
  ReportRow,
  ReportCell,
} from '@reports'
import { useTableColumnSize, useTableRawValue, useTableSorting } from './'

interface UseTableColumnsProps {
  columns: ReportColumnInfo[]
  tableFooter: ReportTotals | null | undefined
  loading: boolean
}

export const useTableColumns = (props: UseTableColumnsProps) => {
  const columnHelper = createColumnHelper<ReportRow>()

  const { getColumnSize, minSize, maxSize } = useTableColumnSize()

  const hasFooter = computed(
    () => !!props.tableFooter && Object.keys(props.tableFooter).length > 0,
  )

  // TODO: Remove hardcoded UIConfig
  const uiConfig = {
    pinned: ['lease__Name'],
  }

  // TODO: Move to mapped response as `displayValue`
  const createCell: ColumnDefTemplate<
    CellContext<ReportRow, ReportRow[keyof ReportRow]>
  > = ({ getValue, column }) => {
    const { value, ...cell } = getValue() ?? {}

    // TODO: Fallback logic should be included in UIConfig?
    // E.g. `uiConfig: { notApplicable: [Sector.UNKNOWN] }`
    // TODO: Format logic should be included in UIConfig?
    // E.g. `uiConfig: { format: 'title' }`
    // Lease Sector
    if (column.id === 'propertyLease__Sector') {
      return {
        ...cell,
        value: value === Sector.UNKNOWN ? '-' : title(value),
      }
    }

    if (!value || typeof value !== 'string') {
      return { ...cell, value: value ?? '-' }
    }

    // TODO: Fallback logic should be included in UIConfig?
    // E.g. `uiConfig: { notApplicable: ['n/a', 'nil', 'null', 'n.a.', 'not applicable'] }`
    if (
      ['n/a', 'nil', 'null', 'n.a.', 'not applicable'].includes(
        value.toLowerCase(),
      )
    ) {
      return { ...cell, value: '-' }
    }

    return { ...cell, value }
  }

  // TODO: API should return `sortable: false` for Clauses and Definitions
  const isColumnSortingEnabled = (columnId: string) => {
    return !columnId.endsWith('Clause') && !columnId.endsWith('Definition')
  }

  const createColumn = (
    column: ReportColumnInfo,
  ): ColumnDef<ReportRow, ReportRow[keyof ReportRow]> => {
    if (column.subColumns) {
      return columnHelper.group({
        id: column.id,
        header: column.name,
        columns: column.subColumns.map(createColumn),
      })
    }

    return columnHelper.accessor(column.id, {
      id: column.id,
      header: column.name,
      footer: hasFooter.value ? props.tableFooter![column.id] : undefined,
      size: getColumnSize(column.id, column.name, column.uiConfig?.size),
      // @ts-expect-error - Our custom feature adds support to function, but we can't argument the type
      minSize: () => minSize.value,
      // @ts-expect-error - Our custom feature adds support to function, but we can't argument the type
      maxSize: () => maxSize.value,
      enablePinning: column.uiConfig?.pinnable ?? true,
      // TODO: Remove hardcoded columns sorting
      enableSorting:
        column.uiConfig?.sortable ?? isColumnSortingEnabled(column.id),
      cell: createCell,
      enableResizing: column.uiConfig?.resizable ?? true,
      meta: {
        visible: !column.uiConfig?.hidden,
        // TODO: Remove hardcoded pinned columns
        fixed: uiConfig.pinned.includes(column.id)
          ? 'left'
          : column.uiConfig?.pinned,
        slotProps: {
          uiConfig: column.uiConfig,
        },
      },
    })
  }

  const columns = computed(() => {
    if (props.loading) {
      return Array.from({ length: 10 }, (_, index) =>
        columnHelper.display({
          id: `skeleton-${index}`,
        }),
      )
    }

    return props.columns.map(createColumn)
  })

  return { columns, hasFooter }
}
